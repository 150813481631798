Uint8Array.prototype.concat = function (other: Uint8Array) {
  const tmp = new Uint8Array(this.length + other.length);
  tmp.set(this, 0);
  tmp.set(other, this.length);
  return tmp;
};

export const COMMANDS = {
  NEW_LINE: new Uint8Array([0x0a]),
  PARTIAL_CUT_1: new Uint8Array([0x1b, 0x6d]),
  FONT_B: new Uint8Array([0x1b, 0x4d, 49]),
  TAB_SIZE_SET: new Uint8Array([0x1b, 0x44, 2]),

  FONT_SIZE_NORMAL: new Uint8Array([0x1d, 0x21, 0x00]), // 1x base size
  FONT_SIZE_L: new Uint8Array([0x1d, 0x21, 0x11]), // 2x base size
  FONT_SIZE_XL: new Uint8Array([0x1d, 0x21, 0x33]), // 4x base size

  BOLD_SET: new Uint8Array([0x1b, 0x45, 0x01]),
  BOLD_RESET: new Uint8Array([0x1b, 0x45, 0x00]),
  UNDERLINE_SET: new Uint8Array([0x1b, 0x2d, 0x01]),
  UNDERLINE_RESET: new Uint8Array([0x1b, 0x2d, 0x00]),

  CENTER_JUSTIFICATION: new Uint8Array([0x1b, 0x61, 0x01]),
  LEFT_JUSTIFICATION: new Uint8Array([0x1b, 0x61, 0x00]),
  RIGHT_JUSTIFICATION: new Uint8Array([0x1b, 0x61, 0x02]),

  DRAW_LINE: new Uint8Array(
    new TextEncoder().encode(Array(56).fill("-").join(""))
  ),

  SPECIAL_CHARACTER_CODE: new Uint8Array([0x1b, 0x74, 0x02]),

  ENGLISH_CHARS: new Uint8Array([0x1b, 0x52, 0x00]),
};

export const reset = COMMANDS.BOLD_RESET.concat(COMMANDS.UNDERLINE_RESET)
  .concat(COMMANDS.LEFT_JUSTIFICATION)
  .concat(COMMANDS.FONT_SIZE_NORMAL);

export const setup = COMMANDS.FONT_SIZE_NORMAL.concat(COMMANDS.FONT_B)
  .concat(COMMANDS.TAB_SIZE_SET)
  .concat(COMMANDS.SPECIAL_CHARACTER_CODE)
  .concat(COMMANDS.ENGLISH_CHARS)
  .concat(reset);

export const feedAndCut = COMMANDS.NEW_LINE.concat(COMMANDS.NEW_LINE)
  .concat(COMMANDS.NEW_LINE)
  .concat(COMMANDS.NEW_LINE)
  .concat(COMMANDS.NEW_LINE)
  .concat(COMMANDS.NEW_LINE)
  .concat(COMMANDS.PARTIAL_CUT_1);
