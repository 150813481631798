import { useState } from "react";
import { useTranslation } from "react-i18next";

import kitchenMenuLogo from "../../../assets/kitchen-menu.svg";
import placeholderImage from "../../../assets/placeholder.png";
import { KitchenMenu as Menu } from "../../types/KitchenMenu";
import * as Price from "../../utils/Price";
import { translate } from "../../utils/translations";
import { useRootState } from "../state/StateProvider";
import * as svg from "../svg";

export const KitchenMenuDialog = ({
    cartQuantity,
    kitchenMenu,
    onCancel
}: {
    cartQuantity: number;
    kitchenMenu: Menu;
    onCancel: () => void;
}) => {
    const { t, i18n } = useTranslation();
    const {
        state: { accessibilityMode },
        dispatch
    } = useRootState("kitchen");

    const [currentFilter, setFilter] = useState<number>(0);

    const products = kitchenMenu.categories[currentFilter].products;

    const CategoryTab = ({ index }: { index: number }) => {
        return (
            <button
                onClick={() => {
                    setFilter(index);
                }}
                className={`
                    w-40 min-w-fit flex-[0_0_3.5rem] rounded p-2 text-center text-xs font-bold capitalize
                    ${
                        index === currentFilter
                            ? "bg-white text-primary-500"
                            : "bg-primary-300 text-white"
                    }
                `}
            >
                {translate(kitchenMenu.categories[index].name, i18n)}
            </button>
        );
    };

    return (
        <>
            <div
                data-testid="kitchenMenu"
                className="z-10 flex h-full flex-col gap-4 bg-primary-500 p-3"
            >
                <div className="flex items-center justify-between">
                    <button
                        className="press-effect rounded bg-primary-200 p-1 text-primary-500"
                        onClick={() => onCancel()}
                    >
                        {svg.leftArrow}
                    </button>
                    <img
                        src={kitchenMenuLogo}
                        className="mx-auto h-20 w-auto"
                    />
                    <div className="text-center">
                        <div className="rounded bg-primary-200 p-2">
                            {svg.shoppingCart}
                        </div>
                        <span className="absolute right-2 top-8 h-[0.75rem] w-[0.75rem] rounded-full bg-red-500 text-[0.5rem] text-white">
                            {cartQuantity}
                        </span>
                    </div>
                </div>

                <div className="flex h-full w-full gap-3 overflow-x-clip overflow-y-scroll">
                    <div className="-mx-2 flex flex-col gap-2 overflow-y-auto px-2">
                        {kitchenMenu.categories.map((_, index) => (
                            <CategoryTab key={index} index={index} />
                        ))}
                    </div>
                    <div
                        role="list"
                        data-testid="products"
                        className="-mx-3 grid flex-1 grid-cols-2 grid-rows-adjust gap-2 overflow-y-auto px-3"
                    >
                        {products.map((product) => (
                            <button
                                disabled={!product.available}
                                role="listitem"
                                data-testid={"fixtasten".concat(
                                    product.barcode
                                )}
                                key={`product-${product.id}`}
                                onClick={() =>
                                    dispatch({
                                        kind: "showKitchenProductSelector",
                                        product
                                    })
                                }
                                className={`
                                    press-effect flex
                                    ${accessibilityMode ? "h-32" : "h-44"}
                                    flex-col items-center
                                    gap-2 p-4 pb-2
                                    ${!product.available ? "opacity-50" : ""}
                                    rounded
                                    bg-white
                                    text-center text-xs font-semibold
                                    text-primary-800
                                `}
                            >
                                <img
                                    src={product.imageUrl ?? placeholderImage}
                                    className={
                                        accessibilityMode ? "h-12" : "h-20"
                                    }
                                />
                                <span className="w-full overflow-hidden text-ellipsis capitalize text-primary-600">
                                    {translate(
                                        product.name,
                                        i18n
                                    ).toLowerCase()}
                                </span>
                                {product.available && product.price && (
                                    <span className="grow content-end font-normal text-primary-400">
                                        {Price.format(product.price)}
                                    </span>
                                )}
                                {!product.available && (
                                    <span
                                        className={`
                                            grow content-end
                                            font-normal text-primary-400
                                        `}
                                    >
                                        {t("kitchenMenu.productUnavailable")}
                                    </span>
                                )}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
