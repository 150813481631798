import {
    PropsWithChildren,
    ReactElement,
    ReactNode,
    useRef,
    useState
} from "react";

import { Modal } from "./Modal";
import { Tenant } from "../types/Config";

type DialogButtonProps = {
    title: string;
    action: () => void;
};

const DialogButton = ({
    type,
    title,
    action
}: {
    type: "primary" | "secondary";
} & DialogButtonProps) => {
    const typeClassName =
        type === "primary"
            ? "bg-primary-500 text-white"
            : "bg-primary-100 text-primary-500 border-2 border-primary-200";

    return (
        <button
            data-testid={`dialog-button-${type}`}
            className={`rounded p-3 text-sm font-semibold ${typeClassName}`}
            onClick={(e) => {
                e.stopPropagation();
                action();
            }}
        >
            {title}
        </button>
    );
};

export const Dialog = ({
    image,
    title,
    theme,
    primaryButton,
    secondaryButton,
    onBackgroundClick,
    dataTestId,
    className,
    children
}: PropsWithChildren<{
    dataTestId?: string;
    image?: ReactElement;
    title: ReactNode;
    theme?: Tenant | "kitchen";
    primaryButton?: DialogButtonProps;
    secondaryButton?: DialogButtonProps;
    className?: string;
    onBackgroundClick?: () => void;
}>) => {
    const [isOpen, setIsOpen] = useState(true);

    const onCloseCallbackRef = useRef<() => void>();

    const closeWith = (action: () => void) => () => {
        onCloseCallbackRef.current = action;
        setIsOpen(false);
    };

    return (
        <Modal
            theme={theme}
            isOpen={isOpen}
            onCloseComplete={() => onCloseCallbackRef.current?.()}
            onBackgroundClick={
                onBackgroundClick && closeWith(onBackgroundClick)
            }
        >
            <div
                data-testid={dataTestId}
                className={`flex w-80 flex-col items-center gap-6 rounded-xl bg-white p-6 shadow-lg ${
                    className ?? ""
                }`}
            >
                <div className="flex flex-col items-center">
                    {image && (
                        <div className="text-primary-500 [&>svg]:h-28">
                            {image}
                        </div>
                    )}

                    <div className="text-center text-xl font-bold text-primary-800">
                        {title}
                    </div>
                </div>

                {children}

                {(primaryButton || secondaryButton) && (
                    <div className="-mx-3 -mb-3 flex gap-3 self-stretch [&>*]:flex-1">
                        {secondaryButton && (
                            <DialogButton
                                type="secondary"
                                title={secondaryButton.title}
                                action={closeWith(secondaryButton.action)}
                            />
                        )}
                        {primaryButton && (
                            <DialogButton
                                type="primary"
                                title={primaryButton.title}
                                action={closeWith(primaryButton.action)}
                            />
                        )}
                    </div>
                )}
            </div>
        </Modal>
    );
};
