export const minus = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect y="9" width="20" height="2" rx="1" fill="currentColor" />
    </svg>
);

export const plus = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect y="9" width="20" height="2" rx="1" fill="currentColor" />
        <rect x="9" width="2" height="20" rx="1" fill="currentColor" />
    </svg>
);

export const trash = (
    <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13 16H15H31"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M29 16V30C29 30.5304 28.7893 31.0391 28.4142 31.4142C28.0391 31.7893 27.5304 32 27 32H17C16.4696 32 15.9609 31.7893 15.5858 31.4142C15.2107 31.0391 15 30.5304 15 30V16M18 16V14C18 13.4696 18.2107 12.9609 18.5858 12.5858C18.9609 12.2107 19.4696 12 20 12H24C24.5304 12 25.0391 12.2107 25.4142 12.5858C25.7893 12.9609 26 13.4696 26 14V16"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M20 21V27"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M24 21V27"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const confirmCart = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 420 300"
        xmlSpace="preserve"
    >
        <style>
            {
                "\n    .st0{fill:#999}.st4{fill:#c1c1c1}.st6{fill:gray}.st7{fill:#fff}.st8{fill:#666}\n  "
            }
        </style>
        <g id="New">
            <path
                className="st0"
                d="M316.26 199.05H214.85c-7.17 0-13.66-4.04-16.96-10.55l-29.86-58.9 146.14 2.49 19.06 38.44c3.14 6.2 2.85 13.45-.8 19.38-3.51 5.73-9.55 9.14-16.17 9.14zm-140.02-64.31 26.11 51.5c2.44 4.82 7.23 7.81 12.5 7.81h101.42c4.86 0 9.31-2.53 11.91-6.76 2.73-4.44 2.95-9.87.59-14.52l-17.72-35.73-134.81-2.3z"
            />
            <path
                className="st4"
                d="M167 201.64v-79.29c0-5.97 1.15-11.87 3.37-17.34l12.93-31.8c1.75-4.31 2.65-8.95 2.65-13.64V49.6h35.72v9.98c0 4.69.9 9.34 2.65 13.64l12.93 31.8c2.23 5.47 3.37 11.38 3.37 17.34v79.29c0 7.58-5.76 13.72-12.87 13.72h-47.88c-7.11-.01-12.87-6.15-12.87-13.73z"
            />
            <path
                className="st6"
                d="M225.97 55.82h-44.2v-3.68c0-5.65 4.58-10.23 10.23-10.23h23.73c5.65 0 10.23 4.58 10.23 10.23v3.68z"
            />
            <path className="st7" d="M180.82 79.32h45.99l2.03 5h-50.05z" />
            <path
                className="st8"
                d="M281.64 107.29s8.01-23.16-17.53-26.45c-.01 0-6.74 20.66 17.53 26.45z"
            />
            <path
                className="st0"
                d="m284.3 107.64-4.95-.68c.11-.83 2.93-20.28 15.63-22.79l.97 4.9c-7.64 1.51-11 13.91-11.65 18.57z"
            />
            <path
                className="st6"
                d="m314.19 132.08-.86 4.78c-.81 3.48-2.11 7.21-3.99 11.19-13.86 29.36-26.26 22.32-31.43 18.99-5.17-3.32-12.03-3.32-16.2-2.82-4.17.51-16.86.71-20.65-28.31-.17-1.31-.29-2.57-.36-3.8-1.57-26.09 17.95-35.06 26.92-31.55 9.39 3.69 8.6 9.1 25.66 5.02 10.06-2.41 23.17 7.43 20.91 26.5z"
            />
            <path
                className="st0"
                d="M168.97 109.41h-59.2a9.08 9.08 0 0 1 9.08-9.08h41.04a9.08 9.08 0 0 1 9.08 9.08z"
            />
            <path
                className="st4"
                d="M168.97 109.41h-59.22l-11.7 22.76 54.69.84z"
            />
            <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="206.117"
                y1="225.08"
                x2="206.117"
                y2="113.882"
            >
                <stop
                    offset="0.015"
                    style={{
                        stopColor: "#e6e6e6"
                    }}
                />
                <stop
                    offset="0.324"
                    style={{
                        stopColor: "#b3b3b3"
                    }}
                />
                <stop
                    offset="0.774"
                    style={{
                        stopColor: "#666"
                    }}
                />
            </linearGradient>
            <path
                d="m314.19 132.08-.86 4.78-16.68 93.09H125.48c-5.38 0-9.99-3.86-10.94-9.16l-3.6-19.34-.93-5-10.45-56.14v-.01l-1.5-8.1v-.01l-.01-.02h.07l136.95-.06h5.63l73.49-.03z"
                style={{
                    fill: "url(#SVGID_1_)"
                }}
            />
            <linearGradient
                id="SVGID_00000165953461306023400750000003548730344727348388_"
                gradientUnits="userSpaceOnUse"
                x1="98.057"
                y1="132.169"
                x2="98.057"
                y2="132.192"
            >
                <stop
                    offset="0"
                    style={{
                        stopColor: "#649d84"
                    }}
                />
                <stop
                    offset="0.49"
                    style={{
                        stopColor: "#89be9b"
                    }}
                />
                <stop
                    offset="1"
                    style={{
                        stopColor: "#b6dcaf"
                    }}
                />
            </linearGradient>
            <path
                style={{
                    fill: "url(#SVGID_00000165953461306023400750000003548730344727348388_)"
                }}
                d="M98.05 132.17h.02l-.01.02z"
            />
            <path
                className="st7"
                d="M204.09 152.79h5V213h-5zm39.94 0-5.9 60.21h-5.03l5.91-60.21zm35.24 0-6.59 44.83-4.94-.73 6.48-44.1zM147.81 213h-5.06l-8.85-60.21h5.06zm32.27 0h-5.03l-5.91-60.21h5.03z"
            />
            <path
                d="M338.22 229.21c0 19.45-15.77 35.22-35.22 35.22s-35.22-15.77-35.22-35.22V194H303c19.45 0 35.22 15.76 35.22 35.21z"
                fill="currentColor"
            />
            <path
                className="st7"
                d="m297.49 249.31-12.97-12.98 3.53-3.53 8.75 8.74 20.87-29.97 4.1 2.86z"
            />
            <circle
                cx="333.12"
                cy="100.96"
                r="4.89"
                style={{
                    fill: "#ccc"
                }}
            />
            <path
                className="st0"
                d="m315.04 60.25.65 3.34c.94 4.82 4.69 8.61 9.5 9.6l3.34.69-3.34.65c-4.82.94-8.61 4.69-9.6 9.5l-.69 3.34-.65-3.34c-.94-4.82-4.69-8.61-9.5-9.6l-3.34-.69 3.34-.65c4.82-.94 8.61-4.69 9.6-9.5l.69-3.34z"
            />
            <path
                className="st8"
                d="m73.4 101.47.65 3.34c.94 4.82 4.69 8.61 9.5 9.6l3.34.69-3.34.65c-4.82.94-8.61 4.69-9.6 9.5l-.69 3.34-.65-3.34c-.94-4.82-4.69-8.61-9.5-9.6l-3.34-.69 3.34-.65c4.82-.94 8.61-4.69 9.6-9.5l.69-3.34z"
            />
            <path className="st0" d="m153.08 132.17 29.64-.04-13.75-22.72z" />
            <path
                className="st0"
                d="m240.63 132.11-29.42 58.75c-3.31 6.61-9.83 10.71-17.03 10.71h-100c-6.59 0-12.63-3.42-16.16-9.14-3.73-6.03-4.06-13.42-.87-19.77l20.13-39.12.76-1.36h6.18l-4.13 8.12-17.95 34.9c-2.3 4.57-2.06 9.93.64 14.3 2.54 4.12 6.7 6.49 11.39 6.49h99.99c5.06 0 9.67-2.91 12.02-7.61l28.17-56.25h6.28z"
            />
        </g>
    </svg>
);

export const pos = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 420 300"
        xmlSpace="preserve"
    >
        <style>{".st5{fill:#ccc}.st7{fill:#fff}"}</style>
        <g id="New">
            <circle className="st5" cx="294.56" cy="65.96" r="4.89" />
            <path
                d="m276.48 25.25.65 3.34c.94 4.82 4.69 8.61 9.5 9.6l3.34.69-3.34.65c-4.82.94-8.61 4.69-9.6 9.5l-.69 3.34-.65-3.34c-.94-4.82-4.69-8.61-9.5-9.6l-3.34-.69 3.34-.65c4.82-.94 8.61-4.69 9.6-9.5l.69-3.34z"
                style={{
                    fill: "gray"
                }}
            />
            <path
                d="m91.26 128.36.65 3.34c.94 4.82 4.69 8.61 9.5 9.6l3.34.69-3.34.65c-4.82.94-8.61 4.69-9.6 9.5l-.69 3.34-.65-3.34c-.94-4.82-4.69-8.61-9.5-9.6l-3.34-.69 3.34-.65c4.82-.94 8.61-4.69 9.6-9.5l.69-3.34z"
                style={{
                    fill: "#c1c1c1"
                }}
            />
            <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="193.733"
                y1="254.464"
                x2="193.733"
                y2="48.049"
            >
                <stop
                    offset="0"
                    style={{
                        stopColor: "#e6e6e6"
                    }}
                />
                <stop
                    offset="0.23"
                    style={{
                        stopColor: "#cdcdcd"
                    }}
                />
                <stop
                    offset="0.714"
                    style={{
                        stopColor: "#8e8e8e"
                    }}
                />
                <stop
                    offset="1"
                    style={{
                        stopColor: "#666"
                    }}
                />
            </linearGradient>
            <path
                d="M242.53 254.46h-98.2c-7.21 0-13.06-5.85-13.06-13.06V61.11c0-7.21 5.85-13.06 13.06-13.06h98.81c7.21 0 13.06 5.85 13.06 13.06V240.8c0 7.54-6.12 13.66-13.67 13.66z"
                style={{
                    fill: "url(#SVGID_1_)"
                }}
            />
            <path className="st7" d="M140.72 62.42h106.03v79.5H140.72z" />
            <path
                d="M213.8 160.87h17.5v5h-17.5zm-28.82 0h17.5v5h-17.5zm-28.82 0h17.5v5h-17.5zm57.64 20.58h17.5v5h-17.5zm-28.82 0h17.5v5h-17.5zm-28.82 0h17.5v5h-17.5zm57.64 20.57h17.5v5h-17.5zm-28.82 0h17.5v5h-17.5zm-7.35 21.92h32.2v5h-32.2zm-21.47-21.92h17.5v5h-17.5z"
                style={{
                    fill: "#999"
                }}
            />
            <path
                className="st5"
                d="M191.23 87.41h5v9.39h-5zm0 26.21h5v9.39h-5zm-15.307-10.863 9.39-.033.017 5-9.39.033zm3.455-8.369 3.535-3.536 5.799 5.798-3.536 3.536zm22.569 8.31 9.39-.07.037 5-9.39.07zm-3.354 11.07 3.523-3.548 5.82 5.777-3.523 3.548zm.14-17.105 5.773-5.823 3.55 3.52-5.772 5.823zm-19.323 19.415 5.774-5.822 3.55 3.521-5.775 5.822z"
            />
            <path
                style={{
                    fill: "#e6e6e6"
                }}
                d="M194.15 168.29H344.6v11.08H194.15z"
            />
            <path
                d="M194.15 185.21v-5.84H344.6v5.84c0 5.81-4.7 10.51-10.51 10.51H204.67c-5.81 0-10.52-4.7-10.52-10.51zm10.52-78.25H334.1c5.81 0 10.51 4.7 10.51 10.51v50.82H194.15v-50.82c0-5.81 4.71-10.51 10.52-10.51z"
                fill="currentColor"
            />
            <path
                className="st7"
                d="M220.56 146.93c-7.28 0-13.2-5.92-13.2-13.2 0-7.28 5.92-13.21 13.2-13.21s13.2 5.92 13.2 13.21c0 7.28-5.92 13.2-13.2 13.2zm0-21.41c-4.52 0-8.2 3.68-8.2 8.21 0 4.52 3.68 8.2 8.2 8.2s8.2-3.68 8.2-8.2c0-4.53-3.68-8.21-8.2-8.21z"
            />
            <path
                className="st7"
                d="M233.66 146.93c-7.28 0-13.2-5.92-13.2-13.2 0-7.28 5.92-13.21 13.2-13.21s13.21 5.92 13.21 13.21c0 7.28-5.93 13.2-13.21 13.2zm0-21.41c-4.52 0-8.2 3.68-8.2 8.21 0 4.52 3.68 8.2 8.2 8.2s8.21-3.68 8.21-8.2c0-4.53-3.68-8.21-8.21-8.21z"
            />
        </g>
    </svg>
);

export const productNotFound = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 420 300"
        xmlSpace="preserve"
    >
        <style>
            {".st5{fill:#ccc}.st7{fill:#fff}.st8{fill:#666}.st60{fill:#e6e6e6}"}
        </style>
        <g id="New">
            <circle className="st5" cx="84.37" cy="111.57" r="5.66" />
            <path
                d="m99.97 62.84-.76 3.86a14.08 14.08 0 0 1-10.98 11.09l-3.86.79 3.86.76a14.08 14.08 0 0 1 11.09 10.98l.79 3.86.76-3.86a14.08 14.08 0 0 1 10.98-11.09l3.86-.79-3.86-.76a14.08 14.08 0 0 1-11.09-10.98l-.79-3.86z"
                style={{
                    fill: "gray"
                }}
            />
            <path
                d="m288.51 165.46.65 3.34c.94 4.82 4.69 8.61 9.5 9.6l3.34.69-3.34.65c-4.82.94-8.61 4.69-9.6 9.5l-.69 3.34-.65-3.34c-.94-4.82-4.69-8.61-9.5-9.6l-3.34-.69 3.34-.65c4.82-.94 8.61-4.69 9.6-9.5l.69-3.34z"
                style={{
                    fill: "#c1c1c1"
                }}
            />
            <path
                className="st5"
                d="m259.25 123.78-7.36 44.95a88.798 88.798 0 0 1-7.95 24.86l-9.68 19.69a40.849 40.849 0 0 1-36.67 22.83h-52.22c-9.7 0-16.07-10.14-11.85-18.88l39.52-39.73 10.63-45.9c1.2-5.2 3.04-10.23 5.47-14.98l70.11 7.16z"
            />
            <path
                fill="currentColor"
                d="m326.63 147.17-60.91-2.81-.68-52.54 64.6-21.27 6.65 37.94z"
            />
            <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="330.129"
                y1="147.18"
                x2="330.129"
                y2="69.967"
            >
                <stop
                    offset="0"
                    style={{
                        stopColor: "#e6e6e6"
                    }}
                />
                <stop
                    offset="0.23"
                    style={{
                        stopColor: "#cdcdcd"
                    }}
                />
                <stop
                    offset="0.714"
                    style={{
                        stopColor: "#8e8e8e"
                    }}
                />
                <stop
                    offset="1"
                    style={{
                        stopColor: "#666"
                    }}
                />
            </linearGradient>
            <path
                d="M304.66 103.59c-4.14 21.14 3.91 40.51 17.97 43.26 14.06 2.75 28.82-12.15 32.96-33.29s-3.91-40.51-17.97-43.26c-14.06-2.76-28.82 12.15-32.96 33.29z"
                style={{
                    fill: "url(#SVGID_1_)"
                }}
            />
            <ellipse
                transform="rotate(-.81 329.008 109.023)"
                className="st7"
                cx="330.13"
                cy="108.95"
                rx="25.95"
                ry="15.11"
            />
            <ellipse
                transform="rotate(-.81 343.201 108.863)"
                className="st8"
                cx="344.37"
                cy="108.78"
                rx="11.68"
                ry="10.41"
            />
            <path
                fill="currentColor"
                d="m262.2 148-81.29-3.88 7.47-46.44 76.39-26.15 7.09 37.8z"
            />
            <linearGradient
                id="SVGID_00000025422919097200639920000013631448595225192369_"
                gradientUnits="userSpaceOnUse"
                x1="265.697"
                y1="148.015"
                x2="265.697"
                y2="70.802"
            >
                <stop
                    offset="0"
                    style={{
                        stopColor: "#e6e6e6"
                    }}
                />
                <stop
                    offset="0.23"
                    style={{
                        stopColor: "#cdcdcd"
                    }}
                />
                <stop
                    offset="0.714"
                    style={{
                        stopColor: "#8e8e8e"
                    }}
                />
                <stop
                    offset="1"
                    style={{
                        stopColor: "#666"
                    }}
                />
            </linearGradient>
            <path
                style={{
                    fill: "url(#SVGID_00000025422919097200639920000013631448595225192369_)"
                }}
                d="M240.23 104.42c-4.14 21.14 3.91 40.51 17.97 43.26 14.06 2.75 28.82-12.15 32.96-33.29 4.14-21.14-3.91-40.51-17.97-43.26-14.06-2.75-28.82 12.15-32.96 33.29z"
            />
            <ellipse
                transform="rotate(-.81 264.77 109.783)"
                className="st7"
                cx="265.7"
                cy="109.79"
                rx="25.95"
                ry="15.11"
            />
            <ellipse
                transform="rotate(-.81 278.964 109.615)"
                className="st8"
                cx="279.94"
                cy="109.61"
                rx="11.68"
                ry="10.41"
            />
            <path
                className="st60"
                d="M204.99 91.25a8.2 8.2 0 0 1-.92 3.75l-21.57 50.14-.34 14.19c-.28 11.78-2.9 23.43-8.06 34.02l-.12.24-20.91 42.52h-19.46c-28.49 0-48.24-28.42-38.3-55.12l18.39-49.38c1.12-4.81 2.78-9.48 4.95-13.91.02-.06.05-.11.08-.17.04-.1.09-.19.15-.29.09-.17.2-.37.34-.62 2.65-5.01 13.74-25.93 16.15-30.75 1.6-3.17 4.35-5.61 7.69-6.81a13.85 13.85 0 0 1 13.77 2.59c1.29-1.13 2.77-2 4.4-2.59 4.91-1.76 10.15-.58 13.81 2.62 1.3-1.14 2.8-2.03 4.44-2.62 1.54-.55 3.12-.82 4.68-.82 4.11 0 8.03 1.85 10.65 5.02a7.87 7.87 0 0 1 1.94-.24c4.78 0 8.24 3.92 8.24 8.23z"
            />
            <path className="st60" d="M118.65 117.7h-.01s.03-.06.09-.17" />
            <path
                d="m168.867 122.434 23.385-41.792 4.363 2.441-23.384 41.793zm-37.053.172 22.816-42.128 4.396 2.38-22.816 42.13zm18.503-.039 23.477-41.901 4.362 2.444-23.477 41.901z"
                style={{
                    fill: "#999"
                }}
            />
        </g>
    </svg>
);

export const welcome = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 700 946.88"
        xmlSpace="preserve"
    >
        <style>
            {
                ".st5{fill:#ccc}.st7{fill:#fff}.st60{fill:#e6e6e6}.st130{fill:#b3b3b3}"
            }
        </style>
        <g id="New">
            <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="347.055"
                y1="917.926"
                x2="347.055"
                y2="639.043"
            >
                <stop
                    offset="0"
                    style={{
                        stopColor: "#ccc"
                    }}
                />
                <stop
                    offset="0.356"
                    style={{
                        stopColor: "#b3b3b3"
                    }}
                />
                <stop
                    offset="0.973"
                    style={{
                        stopColor: "gray"
                    }}
                />
            </linearGradient>
            <path
                style={{
                    fill: "url(#SVGID_1_)"
                }}
                d="M207.61 639.04h278.88v278.88H207.61z"
            />
            <path
                className="st60"
                d="M267.01 869.54h160.13c121.13 0 212.07-110.67 188.59-229.5l-2.06-10.42H80.78c-31.13 121.6 60.72 239.92 186.23 239.92z"
            />
            <linearGradient
                id="SVGID_00000115514612409846135150000008564736539916805763_"
                gradientUnits="userSpaceOnUse"
                x1="347.055"
                y1="705.652"
                x2="347.055"
                y2="504.613"
            >
                <stop
                    offset="0"
                    style={{
                        stopColor: "#ccc"
                    }}
                />
                <stop
                    offset="0.356"
                    style={{
                        stopColor: "#b3b3b3"
                    }}
                />
                <stop
                    offset="0.973"
                    style={{
                        stopColor: "gray"
                    }}
                />
            </linearGradient>
            <path
                style={{
                    fill: "url(#SVGID_00000115514612409846135150000008564736539916805763_)"
                }}
                d="M207.61 504.61h278.88v201.04H207.61z"
            />
            <path className="st130" d="M440.08 504.61v125.22H254.03V504.61" />
            <path
                style={{
                    fill: "#4d4d4d"
                }}
                d="M442.58 632.13H251.53V504.61h5v122.52h181.05V504.61h5z"
            />
            <path className="st130" d="M292.43 501.41h109.26v99.95H292.43z" />
            <path className="st7" d="M292.43 521.42h109.26v75.94H292.43z" />
            <path fill="currentColor" d="M322.61 534.11h48.9v19.87h-48.9z" />
            <path
                d="m171.62 583.8.65 3.34c.94 4.82 4.69 8.61 9.5 9.6l3.34.69-3.34.65c-4.82.94-8.61 4.69-9.6 9.5l-.69 3.34-.65-3.34c-.94-4.82-4.69-8.61-9.5-9.6l-3.33-.68 3.34-.65c4.82-.94 8.61-4.69 9.6-9.5l.68-3.35z"
                style={{
                    fill: "#c1c1c1"
                }}
            />
            <circle className="st5" cx="543.59" cy="572.25" r="4.89" />
            <path
                d="m525.51 525.62.65 3.34c.94 4.82 4.69 8.61 9.5 9.6l3.34.69-3.34.65c-4.82.94-8.61 4.69-9.6 9.5l-.69 3.34-.65-3.34c-.94-4.82-4.69-8.61-9.5-9.6l-3.34-.69 3.34-.65c4.82-.94 8.61-4.69 9.6-9.5l.69-3.34z"
                style={{
                    fill: "gray"
                }}
            />
            <linearGradient
                id="SVGID_00000162339735474129012440000016717003839505051324_"
                gradientUnits="userSpaceOnUse"
                x1="346.436"
                y1="534.106"
                x2="346.436"
                y2="823.079"
            >
                <stop
                    offset="0"
                    style={{
                        stopColor: "currentColor",
                        stopOpacity: 0.4
                    }}
                />
                <stop
                    offset="1"
                    style={{
                        stopColor: "currentColor",
                        stopOpacity: 0
                    }}
                />
            </linearGradient>
            <path
                style={{
                    fill: "url(#SVGID_00000162339735474129012440000016717003839505051324_)"
                }}
                d="m371.51 534.11 179.73 288.97h-409.6l180.97-288.97z"
            />
            <path
                className="st5"
                d="M524.08 505.16H170.03c-9.01 0-16.32-7.3-16.32-16.32V61.49c0-9.01 7.3-16.32 16.32-16.32h354.04c9.01 0 16.32 7.3 16.32 16.32v427.36c0 9.01-7.3 16.31-16.31 16.31z"
            />
            <path className="st60" d="M171.21 65.19h351.7v419.09h-351.7z" />
            <path fill="currentColor" d="M319.86 274.69h60.29v5h-60.29z" />
            <path
                className="st5"
                d="M276.11 298.55h147.78v5H276.11zm29.84 14.69h88.1v5h-88.1z"
            />
            <path className="st7" d="M312.68 350.29h68.75v19.87h-68.75z" />
        </g>
        <g id="Old" />
        <g id="Inspo" />
    </svg>
);

export const itemLost = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 420 300"
        xmlSpace="preserve"
    >
        <style>
            {
                ".st5{fill:#ccc}.st7{fill:#fff}.st8{fill:#666}.st60{fill:#e6e6e6}\n  "
            }
        </style>
        <g id="New">
            <circle className="st5" cx="84.37" cy="111.57" r="5.66" />
            <path
                d="m99.97 62.84-.76 3.86a14.08 14.08 0 0 1-10.98 11.09l-3.86.79 3.86.76a14.08 14.08 0 0 1 11.09 10.98l.79 3.86.76-3.86a14.08 14.08 0 0 1 10.98-11.09l3.86-.79-3.86-.76a14.08 14.08 0 0 1-11.09-10.98l-.79-3.86z"
                style={{
                    fill: "gray"
                }}
            />
            <path
                d="m288.51 165.46.65 3.34c.94 4.82 4.69 8.61 9.5 9.6l3.34.69-3.34.65c-4.82.94-8.61 4.69-9.6 9.5l-.69 3.34-.65-3.34c-.94-4.82-4.69-8.61-9.5-9.6l-3.34-.69 3.34-.65c4.82-.94 8.61-4.69 9.6-9.5l.69-3.34z"
                style={{
                    fill: "#c1c1c1"
                }}
            />
            <path
                className="st5"
                d="m259.25 123.78-7.36 44.95a88.798 88.798 0 0 1-7.95 24.86l-9.68 19.69a40.849 40.849 0 0 1-36.67 22.83h-52.22c-9.7 0-16.07-10.14-11.85-18.88l39.52-39.73 10.63-45.9c1.2-5.2 3.04-10.23 5.47-14.98l70.11 7.16z"
            />
            <path
                fill="currentColor"
                d="m326.63 147.17-60.91-2.81-.68-52.54 64.6-21.27 6.65 37.94z"
            />
            <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="330.129"
                y1="147.18"
                x2="330.129"
                y2="69.967"
            >
                <stop
                    offset="0"
                    style={{
                        stopColor: "#e6e6e6"
                    }}
                />
                <stop
                    offset="0.23"
                    style={{
                        stopColor: "#cdcdcd"
                    }}
                />
                <stop
                    offset="0.714"
                    style={{
                        stopColor: "#8e8e8e"
                    }}
                />
                <stop
                    offset="1"
                    style={{
                        stopColor: "#666"
                    }}
                />
            </linearGradient>
            <path
                d="M304.66 103.59c-4.14 21.14 3.91 40.51 17.97 43.26 14.06 2.75 28.82-12.15 32.96-33.29s-3.91-40.51-17.97-43.26c-14.06-2.76-28.82 12.15-32.96 33.29z"
                style={{
                    fill: "url(#SVGID_1_)"
                }}
            />
            <ellipse
                transform="rotate(-.81 329.008 109.023)"
                className="st7"
                cx="330.13"
                cy="108.95"
                rx="25.95"
                ry="15.11"
            />
            <ellipse
                transform="rotate(-.81 343.201 108.863)"
                className="st8"
                cx="344.37"
                cy="108.78"
                rx="11.68"
                ry="10.41"
            />
            <path
                fill="currentColor"
                d="m262.2 148-81.29-3.88 7.47-46.44 76.39-26.15 7.09 37.8z"
            />
            <linearGradient
                id="SVGID_00000025422919097200639920000013631448595225192369_"
                gradientUnits="userSpaceOnUse"
                x1="265.697"
                y1="148.015"
                x2="265.697"
                y2="70.802"
            >
                <stop
                    offset="0"
                    style={{
                        stopColor: "#e6e6e6"
                    }}
                />
                <stop
                    offset="0.23"
                    style={{
                        stopColor: "#cdcdcd"
                    }}
                />
                <stop
                    offset="0.714"
                    style={{
                        stopColor: "#8e8e8e"
                    }}
                />
                <stop
                    offset="1"
                    style={{
                        stopColor: "#666"
                    }}
                />
            </linearGradient>
            <path
                style={{
                    fill: "url(#SVGID_00000025422919097200639920000013631448595225192369_)"
                }}
                d="M240.23 104.42c-4.14 21.14 3.91 40.51 17.97 43.26 14.06 2.75 28.82-12.15 32.96-33.29 4.14-21.14-3.91-40.51-17.97-43.26-14.06-2.75-28.82 12.15-32.96 33.29z"
            />
            <ellipse
                transform="rotate(-.81 264.77 109.783)"
                className="st7"
                cx="265.7"
                cy="109.79"
                rx="25.95"
                ry="15.11"
            />
            <ellipse
                transform="rotate(-.81 278.964 109.615)"
                className="st8"
                cx="279.94"
                cy="109.61"
                rx="11.68"
                ry="10.41"
            />
            <path
                className="st60"
                d="M204.99 91.25a8.2 8.2 0 0 1-.92 3.75l-21.57 50.14-.34 14.19c-.28 11.78-2.9 23.43-8.06 34.02l-.12.24-20.91 42.52h-19.46c-28.49 0-48.24-28.42-38.3-55.12l18.39-49.38c1.12-4.81 2.78-9.48 4.95-13.91.02-.06.05-.11.08-.17.04-.1.09-.19.15-.29.09-.17.2-.37.34-.62 2.65-5.01 13.74-25.93 16.15-30.75 1.6-3.17 4.35-5.61 7.69-6.81a13.85 13.85 0 0 1 13.77 2.59c1.29-1.13 2.77-2 4.4-2.59 4.91-1.76 10.15-.58 13.81 2.62 1.3-1.14 2.8-2.03 4.44-2.62 1.54-.55 3.12-.82 4.68-.82 4.11 0 8.03 1.85 10.65 5.02a7.87 7.87 0 0 1 1.94-.24c4.78 0 8.24 3.92 8.24 8.23z"
            />
            <path className="st60" d="M118.65 117.7h-.01s.03-.06.09-.17" />
            <path
                d="m168.867 122.434 23.385-41.792 4.363 2.441-23.384 41.793zm-37.053.172 22.816-42.128 4.396 2.38-22.816 42.13zm18.503-.039 23.477-41.901 4.362 2.444-23.477 41.901z"
                style={{
                    fill: "#999"
                }}
            />
        </g>
        <g id="Old" />
        <g id="Inspo" />
    </svg>
);

export const starOutline = (
    <svg width="48" height="46" viewBox="0 0 48 46">
        <path
            d="m24.951 2.927.951-.309c-.599-1.843-3.205-1.843-3.804 0L17.66 16.275H3.3c-1.937 0-2.743 2.479-1.175 3.618l11.617 8.44-4.438 13.656c-.598 1.843 1.51 3.375 3.078 2.236L24 35.785l11.617 8.44c1.567 1.14 3.676-.393 3.078-2.236l-4.438-13.656 11.617-8.44c1.568-1.14.762-3.618-1.175-3.618h-14.36L25.902 2.618l-.95.31Z"
            fill="white"
            stroke="currentColor"
            strokeWidth="2"
        />
    </svg>
);

export const starFilled = (
    <svg width="48" height="46" viewBox="0 0 48 46">
        <path
            d="m24.951 2.927.951-.309c-.599-1.843-3.205-1.843-3.804 0L17.66 16.275H3.3c-1.937 0-2.743 2.479-1.175 3.618l11.617 8.44-4.438 13.656c-.598 1.843 1.51 3.375 3.078 2.236L24 35.785l11.617 8.44c1.567 1.14 3.676-.393 3.078-2.236l-4.438-13.656 11.617-8.44c1.568-1.14.762-3.618-1.175-3.618h-14.36L25.902 2.618l-.95.31Z"
            fill="currentColor"
        />
    </svg>
);

export const eighteenPlus = (
    <svg
        width="228"
        height="116"
        viewBox="0 0 228 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M29.64 65.81H0.710022V52.07H29.65V22.98H43.39V52.07H72.17V65.81H43.39V94.44H29.65V65.81H29.64Z"
            fill="#FF0056"
        />
        <path
            d="M115.58 113.98H97.41L111.84 47.5599C113.26 39.9299 115.35 31.6799 118.1 22.8199C115.2 25.6199 111.76 28.2199 107.79 30.6099L93.51 39.5399L85.42 26.5599L124.2 2.35986H139.32L115.58 113.98Z"
            fill="#FF0056"
        />
        <path
            d="M197.93 0.609863C207.04 0.609863 214.24 2.87986 219.54 7.39986C224.83 11.9299 227.48 18.0099 227.48 25.6499C227.48 32.3699 225.47 38.2099 221.45 43.1699C217.43 48.1299 211.12 52.2899 202.52 55.6499C208.63 59.6199 213.02 63.8299 215.69 68.2799C218.36 72.7399 219.7 77.8599 219.7 83.6599C219.7 89.8199 218.13 95.3299 215 100.19C211.87 105.05 207.4 108.82 201.6 111.49C195.8 114.16 188.9 115.5 180.91 115.5C170.58 115.5 162.5 112.96 156.67 107.87C150.84 102.78 147.93 95.9599 147.93 87.4099C147.93 72.2899 157.29 61.2999 176.03 54.4299C171.4 50.8699 168.08 47.1799 166.07 43.3599C164.06 39.5399 163.05 35.4199 163.05 30.9899C163.05 21.8799 166.28 14.5399 172.75 8.95986C179.2 3.39986 187.6 0.609863 197.93 0.609863ZM187.47 62.6799C179.89 65.4299 174.39 68.6499 170.98 72.3399C167.57 76.0299 165.86 80.5199 165.86 85.8199C165.86 90.5499 167.36 94.3499 170.36 97.1999C173.36 100.05 177.38 101.48 182.42 101.48C188.27 101.48 192.97 99.8499 196.51 96.5899C200.05 93.3299 201.82 89.0799 201.82 83.8399C201.82 79.2099 200.58 75.1999 198.12 71.8199C195.65 68.4199 192.1 65.3799 187.47 62.6799ZM196.41 14.7299C191.68 14.7299 187.86 16.1599 184.96 19.0099C182.06 21.8599 180.61 25.6299 180.61 30.3099C180.61 34.5399 181.6 38.0199 183.59 40.7699C185.57 43.5199 188.22 45.8899 191.53 47.8699C197.38 45.6799 201.9 43.0099 205.08 39.8499C208.26 36.6899 209.85 32.6999 209.85 27.8599C209.85 23.7399 208.63 20.5199 206.19 18.1999C203.74 15.8899 200.48 14.7299 196.41 14.7299Z"
            fill="#FF0056"
        />
    </svg>
);

export const eighteenPlusBadge = (
    <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21Z"
            fill="currentColor"
        />
        <path
            d="M10.6925 25.6678H13.0771V14.1595H10.6846L7.7098 16.2251V18.3944L10.6447 16.3687H10.6925V25.6678Z"
            fill="white"
        />
        <path
            d="M20.1751 25.923C22.9425 25.923 24.8407 24.5513 24.8407 22.5575C24.8407 21.0422 23.748 19.8698 22.137 19.5986V19.5508C23.4769 19.2318 24.3701 18.203 24.3701 16.9508C24.3701 15.1803 22.6475 13.9043 20.1751 13.9043C17.7108 13.9043 15.9722 15.1883 15.9722 16.9429C15.9722 18.2109 16.8734 19.2397 18.2132 19.5508V19.5986C16.6102 19.8618 15.5096 21.0342 15.5096 22.5495C15.5096 24.5433 17.3838 25.923 20.1751 25.923ZM20.1751 24.1685C18.9071 24.1685 18.0218 23.4108 18.0218 22.374C18.0218 21.3372 18.9071 20.5796 20.1751 20.5796C21.4432 20.5796 22.3205 21.3372 22.3205 22.374C22.3205 23.4108 21.4432 24.1685 20.1751 24.1685ZM20.1751 18.825C19.1064 18.825 18.3568 18.1551 18.3568 17.238C18.3568 16.3208 19.1064 15.6429 20.1751 15.6429C21.2358 15.6429 21.9935 16.3128 21.9935 17.238C21.9935 18.1551 21.2358 18.825 20.1751 18.825Z"
            fill="white"
        />
        <path
            d="M31.835 22.071H35.0889V20.0373H31.835V16.592H29.7454V20.0373H26.4915V22.071H29.7454V25.5562H31.835V22.071Z"
            fill="white"
        />
    </svg>
);

export const scanId = (
    <svg
        width="251"
        height="249"
        viewBox="0 0 251 249"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M184.415 115.755H71.4689V228.747H184.415V115.755Z"
            fill="#CCCCCC"
        />
        <path
            d="M95.5257 209.145H160.378C209.435 209.145 246.266 164.306 236.757 116.16L235.922 111.938H20.1029C7.49535 161.206 44.6944 209.145 95.5257 209.145Z"
            fill="#E6E6E6"
        />
        <path
            d="M184.415 61.2891H71.4689V142.743H184.415V61.2891Z"
            fill="#CCCCCC"
        />
        <path
            d="M165.619 61.2891V112.024H90.2689V61.2891"
            fill="url(#paint0_linear_1893_2173)"
        />
        <path
            d="M150.071 68.0999H105.821V98.8679H150.071V68.0999Z"
            fill="white"
        />
        <path
            d="M137.848 73.2414H118.044V81.292H137.848V73.2414Z"
            fill="#FF0056"
        />
        <path
            d="M56.893 93.3739L57.1563 94.7272C57.537 96.6801 59.0557 98.2156 61.0038 98.6167L62.3565 98.8963L61.0038 99.1597C59.0517 99.5405 57.5167 101.06 57.1158 103.009L56.8363 104.362L56.5731 103.009C56.1924 101.056 54.6736 99.5202 52.7256 99.1191L51.377 98.8436L52.7296 98.5803C54.6817 98.1994 56.2167 96.6801 56.6176 94.7312L56.893 93.3739Z"
            fill="#FF0056"
        />
        <path
            d="M207.54 90.6756C208.634 90.6756 209.521 89.7885 209.521 88.6943C209.521 87.6001 208.634 86.7131 207.54 86.7131C206.446 86.7131 205.56 87.6001 205.56 88.6943C205.56 89.7885 206.446 90.6756 207.54 90.6756Z"
            fill="#CCCCCC"
        />
        <path
            d="M200.218 69.8016L200.481 71.1548C200.862 73.1077 202.38 74.6433 204.328 75.0444L205.681 75.324L204.328 75.5873C202.376 75.9682 200.841 77.4875 200.441 79.4364L200.161 80.7896L199.898 79.4364C199.517 77.4835 197.998 75.9479 196.05 75.5468L194.698 75.2672L196.05 75.0039C198.002 74.623 199.537 73.1037 199.938 71.1548L200.218 69.8016Z"
            fill="#FFAD42"
        />
        <path
            d="M199.402 61.3542H56.0543C52.4063 61.3542 49.4467 60.3805 49.4467 59.1774V2.17679C49.4467 0.97502 52.4023 0 56.0543 0H199.398C203.046 0 206.006 0.973686 206.006 2.17679V59.1787C206.006 60.3805 203.05 61.3542 199.402 61.3542Z"
            fill="url(#paint1_linear_1893_2173)"
        />
        <path d="M198.953 0H56.4988V52.8915H198.953V0Z" fill="white" />
        <path
            d="M250.688 184.545V206.895L250.659 223.245V233.206C250.649 241.888 243.538 249 234.845 249L15.7942 248.912C7.10205 248.902 -0.00974553 241.791 1.00246e-05 233.098V184.545"
            fill="url(#paint2_linear_1893_2173)"
        />
        <path
            d="M0.0389404 184.545V118.051C0.0584515 109.369 7.17025 102.257 15.8624 102.257L234.914 102.355C243.606 102.355 250.718 109.466 250.708 118.159V184.545"
            fill="url(#paint3_linear_1893_2173)"
        />
        <path
            d="M104.726 125.192H25.2377V130.07H104.726V125.192Z"
            fill="white"
        />
        <path
            d="M217.598 144.908H25.2377V149.786H217.598V144.908Z"
            fill="white"
        />
        <path
            d="M217.588 164.644H210.271V169.522H217.588V164.644Z"
            fill="white"
        />
        <path
            d="M205.54 169.512H195.852V164.634H205.54V169.512ZM191.121 169.512H181.434V164.634H191.121V169.512ZM176.702 169.512H167.015V164.634H176.702V169.512ZM162.284 169.512H152.596V164.634H162.284V169.512ZM147.865 169.512H138.178V164.634H147.865V169.512ZM133.446 169.512H123.759V164.634H133.446V169.512ZM119.027 169.512H109.34V164.634H119.027V169.512ZM104.609 169.512H94.9215V164.634H104.609V169.512ZM90.1901 169.512H80.5028V164.634H90.1901V169.512ZM75.7714 169.512H66.0841V164.624H75.7714V169.512ZM61.3527 169.512H51.6654V164.624H61.3527V169.512ZM46.934 169.512H37.2467V164.624H46.934V169.512Z"
            fill="white"
        />
        <path
            d="M32.5543 164.556H25.2377V169.434H32.5543V164.556Z"
            fill="white"
        />
        <path
            d="M233.167 238.601H221.09V234.698H229.265V226.679H233.167V238.601Z"
            fill="#FF0056"
        />
        <path
            d="M233.167 206.856H229.265V198.847H221.09V194.944H233.167V206.856Z"
            fill="#FF0056"
        />
        <path
            d="M21.4817 206.856H17.5795V194.944H29.7251V198.847H21.4817V206.856Z"
            fill="#FF0056"
        />
        <path
            d="M29.7251 238.601H17.5795V226.679H21.4817V234.698H29.7251V238.601Z"
            fill="#FF0056"
        />
        <path
            d="M52.8751 210.524V212.602H46.6413V210.524H48.69V206.846C48.6022 206.963 48.4656 207.08 48.29 207.197C48.1046 207.314 47.8998 207.432 47.6754 207.529C47.451 207.627 47.2169 207.714 46.9828 207.773C46.7486 207.832 46.534 207.871 46.3584 207.871V205.734C46.5145 205.734 46.7096 205.685 46.9535 205.578C47.1974 205.471 47.4413 205.344 47.6852 205.198C47.929 205.051 48.1437 204.895 48.3388 204.739C48.5339 204.583 48.6509 204.456 48.6997 204.368H51.0508V210.534H52.8849L52.8751 210.524Z"
            fill="#FF0056"
        />
        <path
            d="M55.0017 212.602C55.0017 212.153 55.031 211.744 55.0993 211.392C55.1675 211.041 55.2846 210.709 55.4505 210.417C55.6163 210.124 55.8309 209.851 56.1138 209.597C56.3968 209.344 56.748 209.1 57.1674 208.866C57.6162 208.612 57.9967 208.407 58.2991 208.241C58.6015 208.075 58.8454 207.929 59.021 207.812C59.1966 207.685 59.3234 207.568 59.4015 207.471C59.4795 207.373 59.5185 207.256 59.5185 207.119C59.5185 206.846 59.421 206.632 59.2259 206.466C59.0308 206.3 58.7576 206.222 58.4064 206.222C58.0064 206.222 57.6552 206.319 57.3528 206.515C57.0504 206.71 56.7675 206.973 56.4845 207.285L55.0115 205.578C55.1675 205.402 55.3724 205.237 55.6358 205.071C55.8992 204.905 56.1919 204.759 56.5138 204.632C56.8357 204.505 57.1967 204.398 57.5869 204.32C57.9771 204.242 58.3869 204.202 58.8064 204.202C59.8502 204.202 60.6404 204.427 61.1965 204.885C61.7525 205.334 62.0257 205.949 62.0257 206.719C62.0257 207.041 61.9769 207.324 61.8696 207.568C61.7623 207.812 61.6355 208.046 61.4696 208.241C61.3038 208.436 61.1184 208.612 60.9233 208.758C60.7185 208.905 60.5136 209.041 60.299 209.168C60.1526 209.256 59.977 209.353 59.7819 209.47C59.5771 209.588 59.3819 209.705 59.1868 209.822C58.9917 209.939 58.8064 210.066 58.6405 210.183C58.4747 210.3 58.3479 210.407 58.2601 210.505H62.1915V212.583H55.0017V212.602Z"
            fill="#FF0056"
        />
        <path
            d="M69.7034 209.188C70.0058 209.246 70.2789 209.353 70.5228 209.5C70.7765 209.646 70.9911 209.841 71.1667 210.066C71.3423 210.29 71.4789 210.553 71.5764 210.846C71.674 211.139 71.7228 211.451 71.7228 211.792C71.7228 212.241 71.635 212.651 71.4496 213.012C71.2642 213.373 71.0008 213.685 70.6594 213.939C70.3179 214.192 69.9082 214.387 69.4204 214.534C68.9424 214.67 68.3961 214.738 67.801 214.738C66.3377 214.738 65.1963 214.309 64.3671 213.451L65.606 211.802C65.8694 212.075 66.1621 212.28 66.4938 212.417C66.8255 212.553 67.2255 212.622 67.684 212.622C68.7766 212.622 69.3131 212.222 69.3131 211.422C69.3131 210.992 69.1473 210.661 68.8254 210.436C68.5034 210.212 67.9961 210.105 67.3035 210.105H66.8742V208.446H67.3523C67.8888 208.446 68.3083 208.339 68.6107 208.114C68.9132 207.89 69.0692 207.597 69.0692 207.236C69.0692 206.915 68.9619 206.68 68.7376 206.515C68.5132 206.349 68.2205 206.271 67.8596 206.271C67.4693 206.271 67.1084 206.368 66.7865 206.554C66.4645 206.739 66.1914 207.012 65.967 207.354L64.5036 205.676C64.6597 205.471 64.8646 205.285 65.1085 205.11C65.3524 204.934 65.645 204.788 65.9572 204.671C66.2792 204.554 66.6206 204.456 66.9816 204.388C67.3523 204.32 67.723 204.29 68.1034 204.29C68.6107 204.29 69.079 204.349 69.5082 204.466C69.9277 204.583 70.2887 204.759 70.5911 204.983C70.8935 205.207 71.1179 205.48 71.2838 205.793C71.4496 206.105 71.5277 206.456 71.5277 206.836C71.5277 207.129 71.4789 207.402 71.3911 207.656C71.3033 207.91 71.1764 208.153 71.0106 208.368C70.8447 208.583 70.6496 208.758 70.4253 208.895C70.2009 209.031 69.9472 209.129 69.6838 209.178L69.7034 209.188Z"
            fill="#FF0056"
        />
        <path
            d="M80.5321 205.822L86.7854 201.354V204.339L83.449 206.612L86.7854 208.719V211.705L80.5321 207.578V205.822Z"
            fill="#FF0056"
        />
        <path
            d="M90.0729 205.822L96.3262 201.354V204.339L92.9898 206.612L96.3262 208.719V211.705L90.0729 207.578V205.822Z"
            fill="#FF0056"
        />
        <path
            d="M99.6237 205.822L105.877 201.354V204.339L102.541 206.612L105.877 208.719V211.705L99.6237 207.578V205.822Z"
            fill="#FF0056"
        />
        <path
            d="M109.165 205.822L115.418 201.354V204.339L112.081 206.612L115.418 208.719V211.705L109.165 207.578V205.822Z"
            fill="#FF0056"
        />
        <path
            d="M118.705 205.822L124.959 201.354V204.339L121.622 206.612L124.959 208.719V211.705L118.705 207.578V205.822Z"
            fill="#FF0056"
        />
        <path
            d="M128.256 205.822L134.51 201.354V204.339L131.173 206.612L134.51 208.719V211.705L128.256 207.578V205.822Z"
            fill="#FF0056"
        />
        <path
            d="M137.797 205.822L144.05 201.354V204.339L140.714 206.612L144.05 208.719V211.705L137.797 207.578V205.822Z"
            fill="#FF0056"
        />
        <path
            d="M147.348 205.822L153.601 201.354V204.339L150.265 206.612L153.601 208.719V211.705L147.348 207.578V205.822Z"
            fill="#FF0056"
        />
        <path
            d="M156.889 205.822L163.142 201.354V204.339L159.806 206.612L163.142 208.719V211.705L156.889 207.578V205.822Z"
            fill="#FF0056"
        />
        <path
            d="M166.439 205.822L172.693 201.354V204.339L169.356 206.612L172.693 208.719V211.705L166.439 207.578V205.822Z"
            fill="#FF0056"
        />
        <path
            d="M175.98 205.822L182.234 201.354V204.339L178.897 206.612L182.234 208.719V211.705L175.98 207.578V205.822Z"
            fill="#FF0056"
        />
        <path
            d="M185.521 205.822L191.775 201.354V204.339L188.438 206.612L191.775 208.719V211.705L185.521 207.578V205.822Z"
            fill="#FF0056"
        />
        <path
            d="M52.8751 226.377V228.455H46.6413V226.377H48.69V222.699C48.6022 222.816 48.4656 222.933 48.29 223.05C48.1046 223.167 47.8998 223.284 47.6754 223.382C47.451 223.479 47.2169 223.567 46.9828 223.626C46.7486 223.684 46.534 223.723 46.3584 223.723V221.587C46.5145 221.587 46.7096 221.538 46.9535 221.431C47.1974 221.323 47.4413 221.197 47.6852 221.05C47.929 220.904 48.1437 220.748 48.3388 220.592C48.5339 220.436 48.6509 220.309 48.6997 220.221H51.0508V226.387H52.8849L52.8751 226.377Z"
            fill="#FF0056"
        />
        <path
            d="M55.0017 228.455C55.0017 228.006 55.031 227.596 55.0993 227.245C55.1675 226.894 55.2846 226.562 55.4505 226.27C55.6163 225.977 55.8309 225.704 56.1138 225.45C56.3968 225.196 56.748 224.953 57.1674 224.718C57.6162 224.465 57.9967 224.26 58.2991 224.094C58.6015 223.928 58.8454 223.782 59.021 223.665C59.1966 223.538 59.3234 223.421 59.4015 223.323C59.4795 223.226 59.5185 223.109 59.5185 222.972C59.5185 222.699 59.421 222.484 59.2259 222.319C59.0308 222.153 58.7576 222.075 58.4064 222.075C58.0064 222.075 57.6552 222.172 57.3528 222.367C57.0504 222.562 56.7675 222.826 56.4845 223.138L55.0115 221.431C55.1675 221.255 55.3724 221.089 55.6358 220.924C55.8992 220.758 56.1919 220.611 56.5138 220.485C56.8357 220.358 57.1967 220.25 57.5869 220.172C57.9771 220.094 58.3869 220.055 58.8064 220.055C59.8502 220.055 60.6404 220.28 61.1965 220.738C61.7525 221.187 62.0257 221.802 62.0257 222.572C62.0257 222.894 61.9769 223.177 61.8696 223.421C61.7623 223.665 61.6355 223.899 61.4696 224.094C61.3038 224.289 61.1184 224.465 60.9233 224.611C60.7185 224.757 60.5136 224.894 60.299 225.021C60.1526 225.109 59.977 225.206 59.7819 225.323C59.5771 225.44 59.3819 225.557 59.1868 225.674C58.9917 225.792 58.8064 225.918 58.6405 226.035C58.4747 226.152 58.3479 226.26 58.2601 226.357H62.1915V228.435H55.0017V228.455Z"
            fill="#FF0056"
        />
        <path
            d="M69.7034 225.04C70.0058 225.099 70.2789 225.206 70.5228 225.353C70.7765 225.499 70.9911 225.694 71.1667 225.918C71.3423 226.143 71.4789 226.406 71.5764 226.699C71.674 226.991 71.7228 227.304 71.7228 227.645C71.7228 228.094 71.635 228.504 71.4496 228.865C71.2642 229.226 71.0008 229.538 70.6594 229.791C70.3179 230.045 69.9082 230.24 69.4204 230.386C68.9424 230.523 68.3961 230.591 67.801 230.591C66.3377 230.591 65.1963 230.162 64.3671 229.304L65.606 227.655C65.8694 227.928 66.1621 228.133 66.4938 228.269C66.8255 228.406 67.2255 228.474 67.684 228.474C68.7766 228.474 69.3131 228.074 69.3131 227.274C69.3131 226.845 69.1473 226.513 68.8254 226.289C68.5034 226.065 67.9961 225.957 67.3035 225.957H66.8742V224.299H67.3523C67.8888 224.299 68.3083 224.192 68.6107 223.967C68.9132 223.743 69.0692 223.45 69.0692 223.089C69.0692 222.767 68.9619 222.533 68.7376 222.367C68.5132 222.202 68.2205 222.123 67.8596 222.123C67.4693 222.123 67.1084 222.221 66.7865 222.406C66.4645 222.592 66.1914 222.865 65.967 223.206L64.5036 221.528C64.6597 221.324 64.8646 221.138 65.1085 220.963C65.3524 220.787 65.645 220.641 65.9572 220.524C66.2792 220.406 66.6206 220.309 66.9816 220.241C67.3523 220.172 67.723 220.143 68.1034 220.143C68.6107 220.143 69.079 220.202 69.5082 220.319C69.9277 220.436 70.2887 220.611 70.5911 220.836C70.8935 221.06 71.1179 221.333 71.2838 221.645C71.4496 221.958 71.5277 222.309 71.5277 222.689C71.5277 222.982 71.4789 223.255 71.3911 223.509C71.3033 223.762 71.1764 224.006 71.0106 224.221C70.8447 224.436 70.6496 224.611 70.4253 224.748C70.2009 224.884 69.9472 224.982 69.6838 225.031L69.7034 225.04Z"
            fill="#FF0056"
        />
        <path
            d="M80.5321 221.675L86.7854 217.207V220.192L83.449 222.465L86.7854 224.572V227.557L80.5321 223.431V221.675Z"
            fill="#FF0056"
        />
        <path
            d="M90.0729 221.675L96.3262 217.207V220.192L92.9898 222.465L96.3262 224.572V227.557L90.0729 223.431V221.675Z"
            fill="#FF0056"
        />
        <path
            d="M99.6237 221.675L105.877 217.207V220.192L102.541 222.465L105.877 224.572V227.557L99.6237 223.431V221.675Z"
            fill="#FF0056"
        />
        <path
            d="M109.165 221.675L115.418 217.207V220.192L112.081 222.465L115.418 224.572V227.557L109.165 223.431V221.675Z"
            fill="#FF0056"
        />
        <path
            d="M118.705 221.675L124.959 217.207V220.192L121.622 222.465L124.959 224.572V227.557L118.705 223.431V221.675Z"
            fill="#FF0056"
        />
        <path
            d="M128.256 221.675L134.51 217.207V220.192L131.173 222.465L134.51 224.572V227.557L128.256 223.431V221.675Z"
            fill="#FF0056"
        />
        <path
            d="M137.797 221.675L144.05 217.207V220.192L140.714 222.465L144.05 224.572V227.557L137.797 223.431V221.675Z"
            fill="#FF0056"
        />
        <path
            d="M147.348 221.675L153.601 217.207V220.192L150.265 222.465L153.601 224.572V227.557L147.348 223.431V221.675Z"
            fill="#FF0056"
        />
        <path
            d="M156.889 221.675L163.142 217.207V220.192L159.806 222.465L163.142 224.572V227.557L156.889 223.431V221.675Z"
            fill="#FF0056"
        />
        <path
            d="M166.439 221.675L172.693 217.207V220.192L169.356 222.465L172.693 224.572V227.557L166.439 223.431V221.675Z"
            fill="#FF0056"
        />
        <path
            d="M175.98 221.675L182.234 217.207V220.192L178.897 222.465L182.234 224.572V227.557L175.98 223.431V221.675Z"
            fill="#FF0056"
        />
        <path
            d="M185.521 221.675L191.775 217.207V220.192L188.438 222.465L191.775 224.572V227.557L185.521 223.431V221.675Z"
            fill="#FF0056"
        />
        <path
            d="M137.848 73.2414L210.638 190.321H44.7511L118.044 73.2414H137.848Z"
            fill="url(#paint4_linear_1893_2173)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1893_2173"
                x1="127.944"
                y1="61.2904"
                x2="127.944"
                y2="112.025"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
                <stop offset="1" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_1893_2173"
                x1="127.728"
                y1="0.000760275"
                x2="127.728"
                y2="61.3544"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
                <stop offset="1" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_1893_2173"
                x1="125.349"
                y1="249"
                x2="125.349"
                y2="184.545"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E6E6E6" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_1893_2173"
                x1="125.369"
                y1="184.545"
                x2="125.369"
                y2="102.257"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E6E6E6" />
                <stop offset="0.23" stopColor="#CDCDCD" />
                <stop offset="0.71" stopColor="#8E8E8E" />
                <stop offset="1" stopColor="#666666" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_1893_2173"
                x1="127.693"
                y1="73.2397"
                x2="127.693"
                y2="190.321"
                gradientUnits="userSpaceOnUse"
            >
                <stop
                    offset="5.79195e-07"
                    stopColor="#FF0056"
                    stopOpacity="0.4"
                />
                <stop offset="1" stopColor="#FF0056" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);

export const greenCheckmark = (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 40 40"
        enableBackground="new 0 0 40 40"
    >
        <g>
            <path
                fill="#18D769"
                d="M20.1,38.4c-10.2,0-18.5-8.3-18.5-18.5S9.9,1.4,20.1,1.4s18.5,8.3,18.5,18.5S30.3,38.4,20.1,38.4z"
            />
            <path
                fill="none"
                stroke="#FFFFFF"
                strokeWidth="3"
                strokeMiterlimit="10"
                d="M10.6,20.7l5.8,5.8l13.2-13.2"
            />
        </g>
    </svg>
);

export const play = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="66"
        height="62"
        viewBox="0 0 66 62"
        fill="none"
    >
        <path
            d="M33.0263 58.8706C49.4406 58.8706 62.747 46.3346 62.747 30.8706C62.747 15.4066 49.4406 2.87061 33.0263 2.87061C16.6121 2.87061 3.30566 15.4066 3.30566 30.8706C3.30566 46.3346 16.6121 58.8706 33.0263 58.8706Z"
            stroke="white"
            strokeWidth="5.36156"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M27.0825 19.6709L44.9149 30.8709L27.0825 42.0709V19.6709Z"
            stroke="white"
            strokeWidth="5.36156"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const wheelchair = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="48"
        height="48"
        fill="currentColor"
    >
        <path d="M12 2a1.999 1.999 0 1 0 0 4 1.999 1.999 0 1 0 0-4Zm0 5c-1.102 0-2 .898-2 2v5c0 1.102.898 2 2 2h4.5l2.688 3.594 1.625-1.188-3-4L17.5 14H14V9c0-1.102-.898-2-2-2Zm-3 3c-2.8.602-5 3-5 6 0 3.3 2.7 6 6 6 3 0 5.406-2.2 5.906-5h-2c-.398 1.7-2.008 3-3.906 3-2.2 0-4-1.8-4-4 0-1.898 1.3-3.406 3-3.906Z" />
    </svg>
);

export const expand = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width={48}
        height={48}
        fill="currentColor"
    >
        <path d="M5 5v11a2 2 0 1 0 4 0v-4.172l9.586 9.586a2 2 0 1 0 2.828-2.828L11.828 9H16a2 2 0 1 0 0-4H5zm29 0a2 2 0 1 0 0 4h4.172l-9.586 9.586a2 2 0 1 0 2.828 2.828L41 11.828V16a2 2 0 1 0 4 0V5H34zM19.96 27.98a2 2 0 0 0-1.374.606L9 38.172V34a2 2 0 0 0-2.03-2.027A2 2 0 0 0 5 34v11h11a2 2 0 1 0 0-4h-4.172l9.586-9.586a2 2 0 0 0-1.453-3.434zm10.019 0a2 2 0 0 0-1.393 3.434L38.172 41H34a2 2 0 1 0 0 4h11V34a2 2 0 0 0-2.03-2.027A2 2 0 0 0 41 34v4.172l-9.586-9.586a2 2 0 0 0-1.435-.606z" />
    </svg>
);

export const scan = (
    <svg
        width="342"
        height="188"
        viewBox="0 0 342 188"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_4236_913)">
            <path
                d="M318 0.939941L318.68 4.39994C319.66 9.38994 323.53 13.2999 328.51 14.3199L331.96 15.0299L328.5 15.7099C323.51 16.6899 319.6 20.5599 318.58 25.5399L317.87 28.9899L317.19 25.5299C316.21 20.5399 312.34 16.6299 307.36 15.6099L303.91 14.8999L307.37 14.2199C312.36 13.2399 316.27 9.36994 317.29 4.38994L318 0.939941Z"
                fill="#7FB796"
            />
            <path
                d="M336.85 51.7497C339.551 51.7497 341.74 49.5604 341.74 46.8597C341.74 44.1591 339.551 41.9697 336.85 41.9697C334.149 41.9697 331.96 44.1591 331.96 46.8597C331.96 49.5604 334.149 51.7497 336.85 51.7497Z"
                fill="#CCCCCC"
            />
            <path
                d="M14.0299 125.42L14.6799 128.76C15.6199 133.58 19.3699 137.37 24.1799 138.36L27.5199 139.05L24.1799 139.7C19.3599 140.64 15.5699 144.39 14.5799 149.2L13.8899 152.54L13.2399 149.2C12.2999 144.38 8.5499 140.59 3.7399 139.6L0.399902 138.91L3.7399 138.26C8.5599 137.32 12.3499 133.57 13.3399 128.76L14.0299 125.42Z"
                fill="#FFAD42"
            />
            <path
                d="M49.71 60.7198H44.71V38.5698H67.19V43.5698H49.71V60.7198Z"
                fill="currentColor"
            />
            <path
                d="M67.0299 187.64H44.8799V165.16H49.8799V182.64H67.0299V187.64Z"
                fill="currentColor"
            />
            <path
                d="M299.97 187.64H277.48V182.64H294.97V165.49H299.97V187.64Z"
                fill="currentColor"
            />
            <path
                d="M299.8 61.0498H294.8V43.5698H277.65V38.5698H299.8V61.0498Z"
                fill="currentColor"
            />
            <path
                d="M114.68 66.5601H102.97V111.6H114.68V66.5601Z"
                fill="url(#paint0_linear_4236_913)"
            />
            <path
                d="M114.68 125.17H102.97V156.63H114.68V125.17Z"
                fill="url(#paint1_linear_4236_913)"
            />
            <path
                d="M141.5 66.5898H129.77V111.6H141.5V66.5898Z"
                fill="url(#paint2_linear_4236_913)"
            />
            <path
                d="M141.5 125.17H129.77V156.66H141.5V125.17Z"
                fill="url(#paint3_linear_4236_913)"
            />
            <path
                d="M243.32 66.5698H234.62V111.6H243.32V66.5698Z"
                fill="url(#paint4_linear_4236_913)"
            />
            <path
                d="M243.32 125.17H234.62V156.66H243.32V125.17Z"
                fill="url(#paint5_linear_4236_913)"
            />
            <path
                d="M219.46 66.5601H210.77V111.6H219.46V66.5601Z"
                fill="url(#paint6_linear_4236_913)"
            />
            <path
                d="M219.46 125.17H210.77V156.65H219.46V125.17Z"
                fill="url(#paint7_linear_4236_913)"
            />
            <path
                d="M201.47 66.5698H192.75V111.6H201.47V66.5698Z"
                fill="url(#paint8_linear_4236_913)"
            />
            <path
                d="M201.47 125.17H192.75V156.65H201.47V125.17Z"
                fill="url(#paint9_linear_4236_913)"
            />
            <path
                d="M93.7903 66.5801H88.0703V111.6H93.7903V66.5801Z"
                fill="url(#paint10_linear_4236_913)"
            />
            <path
                d="M93.7903 125.17H88.0703V156.66H93.7903V125.17Z"
                fill="url(#paint11_linear_4236_913)"
            />
            <path
                d="M261.2 66.5801H255.47V111.6H261.2V66.5801Z"
                fill="url(#paint12_linear_4236_913)"
            />
            <path
                d="M261.2 125.17H255.47V156.66H261.2V125.17Z"
                fill="url(#paint13_linear_4236_913)"
            />
            <path
                d="M252.3 66.5801H246.56V111.6H252.3V66.5801Z"
                fill="url(#paint14_linear_4236_913)"
            />
            <path
                d="M252.3 125.17H246.56V156.66H252.3V125.17Z"
                fill="url(#paint15_linear_4236_913)"
            />
            <path
                d="M150.78 66.5801H145.04V111.6H150.78V66.5801Z"
                fill="url(#paint16_linear_4236_913)"
            />
            <path
                d="M150.78 125.17H145.04V156.66H150.78V125.17Z"
                fill="url(#paint17_linear_4236_913)"
            />
            <path
                d="M186.54 66.5801H180.76V111.6H186.54V66.5801Z"
                fill="url(#paint18_linear_4236_913)"
            />
            <path
                d="M186.54 125.17H180.76V156.65H186.54V125.17Z"
                fill="url(#paint19_linear_4236_913)"
            />
            <path
                d="M177.65 66.5698H171.9V111.6H177.65V66.5698Z"
                fill="url(#paint20_linear_4236_913)"
            />
            <path
                d="M177.65 125.17H171.9V156.66H177.65V125.17Z"
                fill="url(#paint21_linear_4236_913)"
            />
            <path
                d="M78.9203 66.5601H76.1104V111.6H78.9203V66.5601Z"
                fill="url(#paint22_linear_4236_913)"
            />
            <path
                d="M78.9203 125.17H76.1104V164.52H78.9203V125.17Z"
                fill="url(#paint23_linear_4236_913)"
            />
            <path
                d="M228.44 66.5698H225.65V111.6H228.44V66.5698Z"
                fill="url(#paint24_linear_4236_913)"
            />
            <path
                d="M228.44 125.17H225.65V156.66H228.44V125.17Z"
                fill="url(#paint25_linear_4236_913)"
            />
            <path
                d="M162.7 66.5601H159.95V111.6H162.7V66.5601Z"
                fill="url(#paint26_linear_4236_913)"
            />
            <path
                d="M162.7 125.17H159.95V164.52H162.7V125.17Z"
                fill="url(#paint27_linear_4236_913)"
            />
            <path
                d="M72.9402 66.5801H70.1602V111.6H72.9402V66.5801Z"
                fill="url(#paint28_linear_4236_913)"
            />
            <path
                d="M72.9402 125.17H70.1602V164.55H72.9402V125.17Z"
                fill="url(#paint29_linear_4236_913)"
            />
            <path
                d="M168.66 66.5898H165.87V111.6H168.66V66.5898Z"
                fill="url(#paint30_linear_4236_913)"
            />
            <path
                d="M168.66 125.17H165.87V164.55H168.66V125.17Z"
                fill="url(#paint31_linear_4236_913)"
            />
            <path
                d="M270.18 66.5801H267.4V111.6H270.18V66.5801Z"
                fill="url(#paint32_linear_4236_913)"
            />
            <path
                d="M270.18 125.17H267.4V164.55H270.18V125.17Z"
                fill="url(#paint33_linear_4236_913)"
            />
            <path
                d="M276.11 66.5801H273.32V111.6H276.11V66.5801Z"
                fill="url(#paint34_linear_4236_913)"
            />
            <path
                d="M276.11 125.17H273.32V164.55H276.11V125.17Z"
                fill="url(#paint35_linear_4236_913)"
            />
            <path
                d="M207.55 66.5898H204.76V111.6H207.55V66.5898Z"
                fill="url(#paint36_linear_4236_913)"
            />
            <path
                d="M207.55 125.17H204.76V156.66H207.55V125.17Z"
                fill="url(#paint37_linear_4236_913)"
            />
            <path
                d="M126.57 66.5601H123.81V111.6H126.57V66.5601Z"
                fill="url(#paint38_linear_4236_913)"
            />
            <path
                d="M126.57 125.17H123.81V156.63H126.57V125.17Z"
                fill="url(#paint39_linear_4236_913)"
            />
            <path
                d="M120.66 66.5898H117.88V111.6H120.66V66.5898Z"
                fill="url(#paint40_linear_4236_913)"
            />
            <path
                d="M120.66 125.17H117.88V156.66H120.66V125.17Z"
                fill="url(#paint41_linear_4236_913)"
            />
            <path
                d="M99.8 66.5898H97.02V111.6H99.8V66.5898Z"
                fill="url(#paint42_linear_4236_913)"
            />
            <path
                d="M99.8 125.17H97.02V156.66H99.8V125.17Z"
                fill="url(#paint43_linear_4236_913)"
            />
            <path
                d="M156.78 66.5601H153.99V111.6H156.78V66.5601Z"
                fill="url(#paint44_linear_4236_913)"
            />
            <path
                d="M156.78 125.17H153.99V156.63H156.78V125.17Z"
                fill="url(#paint45_linear_4236_913)"
            />
            <path
                d="M287.34 109.1H60.1401V114.1H287.34V109.1Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_4236_913"
                x1="108.823"
                y1="66.559"
                x2="108.823"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_4236_913"
                x1="108.823"
                y1="125.169"
                x2="108.823"
                y2="156.629"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_4236_913"
                x1="135.633"
                y1="66.5887"
                x2="135.633"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_4236_913"
                x1="135.633"
                y1="125.169"
                x2="135.633"
                y2="156.659"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_4236_913"
                x1="238.968"
                y1="66.569"
                x2="238.968"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_4236_913"
                x1="238.968"
                y1="125.169"
                x2="238.968"
                y2="156.659"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_4236_913"
                x1="215.113"
                y1="66.559"
                x2="215.113"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_4236_913"
                x1="215.113"
                y1="125.169"
                x2="215.113"
                y2="156.649"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint8_linear_4236_913"
                x1="197.108"
                y1="66.569"
                x2="197.108"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint9_linear_4236_913"
                x1="197.108"
                y1="125.169"
                x2="197.108"
                y2="156.649"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint10_linear_4236_913"
                x1="90.9279"
                y1="66.579"
                x2="90.9279"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint11_linear_4236_913"
                x1="90.9279"
                y1="125.169"
                x2="90.9279"
                y2="156.659"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint12_linear_4236_913"
                x1="258.333"
                y1="66.579"
                x2="258.333"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint13_linear_4236_913"
                x1="258.333"
                y1="125.169"
                x2="258.333"
                y2="156.659"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint14_linear_4236_913"
                x1="249.428"
                y1="66.579"
                x2="249.428"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint15_linear_4236_913"
                x1="249.428"
                y1="125.169"
                x2="249.428"
                y2="156.659"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint16_linear_4236_913"
                x1="147.908"
                y1="66.579"
                x2="147.908"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint17_linear_4236_913"
                x1="147.908"
                y1="125.169"
                x2="147.908"
                y2="156.659"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint18_linear_4236_913"
                x1="183.648"
                y1="66.579"
                x2="183.648"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint19_linear_4236_913"
                x1="183.648"
                y1="125.169"
                x2="183.648"
                y2="156.649"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint20_linear_4236_913"
                x1="174.773"
                y1="66.569"
                x2="174.773"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint21_linear_4236_913"
                x1="174.773"
                y1="125.169"
                x2="174.773"
                y2="156.659"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint22_linear_4236_913"
                x1="77.513"
                y1="66.559"
                x2="77.513"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint23_linear_4236_913"
                x1="77.5129"
                y1="125.169"
                x2="77.5129"
                y2="164.519"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint24_linear_4236_913"
                x1="227.043"
                y1="66.569"
                x2="227.043"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint25_linear_4236_913"
                x1="227.043"
                y1="125.169"
                x2="227.043"
                y2="156.659"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint26_linear_4236_913"
                x1="161.323"
                y1="66.559"
                x2="161.323"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint27_linear_4236_913"
                x1="161.323"
                y1="125.169"
                x2="161.323"
                y2="164.519"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint28_linear_4236_913"
                x1="71.5478"
                y1="66.579"
                x2="71.5478"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint29_linear_4236_913"
                x1="71.5478"
                y1="125.169"
                x2="71.5478"
                y2="164.549"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint30_linear_4236_913"
                x1="167.263"
                y1="66.5887"
                x2="167.263"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint31_linear_4236_913"
                x1="167.263"
                y1="125.169"
                x2="167.263"
                y2="164.549"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint32_linear_4236_913"
                x1="268.788"
                y1="66.579"
                x2="268.788"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint33_linear_4236_913"
                x1="268.788"
                y1="125.169"
                x2="268.788"
                y2="164.549"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint34_linear_4236_913"
                x1="274.713"
                y1="66.579"
                x2="274.713"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint35_linear_4236_913"
                x1="274.713"
                y1="125.169"
                x2="274.713"
                y2="164.549"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint36_linear_4236_913"
                x1="206.153"
                y1="66.5887"
                x2="206.153"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint37_linear_4236_913"
                x1="206.153"
                y1="125.169"
                x2="206.153"
                y2="156.659"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint38_linear_4236_913"
                x1="125.188"
                y1="66.559"
                x2="125.188"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint39_linear_4236_913"
                x1="125.188"
                y1="125.169"
                x2="125.188"
                y2="156.629"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint40_linear_4236_913"
                x1="119.268"
                y1="66.5887"
                x2="119.268"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint41_linear_4236_913"
                x1="119.268"
                y1="125.169"
                x2="119.268"
                y2="156.659"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint42_linear_4236_913"
                x1="98.4076"
                y1="66.5887"
                x2="98.4076"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint43_linear_4236_913"
                x1="98.4076"
                y1="125.169"
                x2="98.4076"
                y2="156.659"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <linearGradient
                id="paint44_linear_4236_913"
                x1="155.383"
                y1="66.559"
                x2="155.383"
                y2="111.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#649D84" />
                <stop offset="0.4897" stopColor="#89BE9B" />
            </linearGradient>
            <linearGradient
                id="paint45_linear_4236_913"
                x1="155.383"
                y1="125.169"
                x2="155.383"
                y2="156.629"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.301" stopColor="#89BE9B" />
                <stop offset="0.8199" stopColor="#B6DCAF" />
            </linearGradient>
            <clipPath id="clip0_4236_913">
                <rect width="342" height="188" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const questionMark = (
    <svg
        width="23"
        height="22"
        viewBox="0 0 23 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.5352 21C17.058 21 21.5352 16.5228 21.5352 11C21.5352 5.47715 17.058 1 11.5352 1C6.01231 1 1.53516 5.47715 1.53516 11C1.53516 16.5228 6.01231 21 11.5352 21Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.625 8.00011C8.8601 7.33178 9.32415 6.76822 9.93495 6.40924C10.5458 6.05027 11.2639 5.91905 11.9622 6.03882C12.6605 6.1586 13.2938 6.52164 13.7501 7.06364C14.2063 7.60564 14.4561 8.29163 14.455 9.00011C14.455 11.0001 11.455 12.0001 11.455 12.0001"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.5352 16H11.5452"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const chevronLeft = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.90005 12C6.90005 11.775 6.97505 11.5875 7.12505 11.4L15.6 2.77498C15.9375 2.43748 16.4625 2.43748 16.8 2.77498C17.1375 3.11248 17.1375 3.63748 16.8 3.97498L8.96255 12L16.875 20.025C17.2125 20.3625 17.2125 20.8875 16.875 21.225C16.5375 21.5625 16.0125 21.5625 15.675 21.225L7.20005 12.6C7.01255 12.4125 6.90005 12.225 6.90005 12Z"
            fill="currentColor"
        />
    </svg>
);

export const chevronRight = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.1 12C17.1 12.225 17.025 12.4125 16.875 12.6L8.39995 21.225C8.06245 21.5625 7.53745 21.5625 7.19995 21.225C6.86245 20.8875 6.86245 20.3625 7.19995 20.025L15.0375 12L7.12495 3.97502C6.78745 3.63752 6.78745 3.11252 7.12495 2.77502C7.46245 2.43752 7.98745 2.43752 8.32495 2.77502L16.8 11.4C16.9875 11.5875 17.1 11.775 17.1 12Z"
            fill="currentColor"
        />
    </svg>
);

export const scoIcon = (
    <svg
        width="55"
        height="55"
        viewBox="0 0 48 48"
        fill="none"
        className="inline"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="24" cy="24" r="24" fill="currentColor" />
        <path
            d="M31.2787 11H18.1738C16.6752 11 15.4529 12.1856 15.4529 13.6392V15.7423H16.8665V13.6392C16.8665 12.9381 17.451 12.3711 18.1738 12.3711H31.2787C32.0014 12.3711 32.586 12.9381 32.586 13.6392V34.3608C32.586 35.0619 32.0014 35.6289 31.2787 35.6289H18.1738C17.451 35.6289 16.8665 35.0619 16.8665 34.3608V32.2577H15.4529V34.3608C15.4529 35.8144 16.6752 37 18.1738 37H31.2787C32.7773 37 33.9996 35.8144 33.9996 34.3608V13.6392C33.9996 12.1856 32.7773 11 31.2787 11Z"
            fill="white"
        />
        <path
            d="M21.3096 27.5053V24.9177C21.3096 23.928 20.7357 23.0517 19.896 22.6084V20.6084C19.896 19.1548 18.6737 17.9692 17.1751 17.9692H15.1345C13.6359 17.9692 12.4136 19.1548 12.4136 20.6084H13.8272C13.8272 19.9074 14.4117 19.3404 15.1345 19.3404H17.1751C17.8979 19.3404 18.4824 19.9074 18.4824 20.6084V22.2888H13.7209C12.2223 22.2888 11 23.4744 11 24.928V27.5156C11 28.9692 12.2223 30.1548 13.7209 30.1548H18.5887C20.0873 30.1548 21.3096 28.9692 21.3096 27.5156V27.5053ZM12.4136 27.5053V24.9177C12.4136 24.2167 12.9982 23.6497 13.7209 23.6497H18.5887C19.3115 23.6497 19.896 24.2167 19.896 24.9177V27.5053C19.896 28.2064 19.3115 28.7734 18.5887 28.7734H13.7209C12.9982 28.7734 12.4136 28.2064 12.4136 27.5053Z"
            fill="white"
        />
        <path
            d="M16.8665 25.3711H15.4529V27.0515H16.8665V25.3711Z"
            fill="white"
        />
    </svg>
);

export const shoppingCart = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9 22C9.55228 22 10 21.5523 10 21C10 20.4477 9.55228 20 9 20C8.44772 20 8 20.4477 8 21C8 21.5523 8.44772 22 9 22Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M20 22C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20C19.4477 20 19 20.4477 19 21C19 21.5523 19.4477 22 20 22Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1 1H5L7.68 14.39C7.77145 14.8504 8.02191 15.264 8.38755 15.5583C8.75318 15.8526 9.2107 16.009 9.68 16H19.4C19.8693 16.009 20.3268 15.8526 20.6925 15.5583C21.0581 15.264 21.3086 14.8504 21.4 14.39L23 6H6"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const leftArrow = (
    <svg
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M32.4583 20.5H8.54163"
            stroke="currentColor"
            strokeWidth="3.41667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M20.5 32.4583L8.54163 20.5L20.5 8.54166"
            stroke="currentColor"
            strokeWidth="3.41667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
