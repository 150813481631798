import { ChangeEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";

import placeholderImage from "../../../assets/placeholder.png";
import {
    KitchenProduct,
    KitchenProductAddOn,
    KitchenProductAddOnOption
} from "../../types/KitchenMenu";
import * as Price from "../../utils/Price";
import { translate } from "../../utils/translations";
import { Button } from "../Button";
import { Dialog } from "../Dialog";
import { useRootAndSubState } from "../state/StateProvider";
import * as svg from "../svg";

type KitchenProductSelectorDialogProps = {
    product: KitchenProduct;
    onConfirm: (
        productId: number,
        barcode: string,
        quantity: number,
        addOnOptionIds: number[]
    ) => void;
    onCancel: () => void;
};

export const KitchenProductSelectorDialog = ({
    product,
    onConfirm,
    onCancel
}: KitchenProductSelectorDialogProps) => {
    useRootAndSubState("kitchen", "productSelector");

    const { t, i18n } = useTranslation();
    const [selectedOptionIds, setSelectedOptionIds] = useState(
        () => new Set<number>()
    );

    const ProductAddOnOption = ({
        id,
        label,
        name,
        type,
        checked,
        onChange
    }: {
        id: number;
        label: string;
        name?: string;
        type: string;
        checked: boolean;
        onChange: ChangeEventHandler<HTMLInputElement> | undefined;
    }) => {
        return (
            <div className="flex items-center rounded-lg border-2 border-primary-200 bg-white p-3">
                <input
                    className="mr-2 h-5 w-5 accent-primary-500"
                    id={`option-${id}`}
                    type={type}
                    name={name}
                    checked={checked}
                    onChange={onChange}
                />
                <label
                    className="grow text-start text-primary-600"
                    htmlFor={`option-${id}`}
                >
                    {label}
                </label>
            </div>
        );
    };

    const CheckboxGroup = ({
        options
    }: {
        options: KitchenProductAddOnOption[];
    }) => {
        return (
            <div className="grid grid-cols-2 gap-2">
                {options.map((option) => (
                    <ProductAddOnOption
                        key={option.id}
                        id={option.id}
                        label={translate(option.name, i18n)}
                        type="checkbox"
                        checked={selectedOptionIds.has(option.id)}
                        onChange={() => {
                            setSelectedOptionIds((optionIds) => {
                                const updatedOptions = new Set(optionIds);

                                if (optionIds.has(option.id)) {
                                    updatedOptions.delete(option.id);
                                } else {
                                    updatedOptions.add(option.id);
                                }

                                return updatedOptions;
                            });
                        }}
                    />
                ))}
            </div>
        );
    };

    const RadioGroup = ({
        id,
        options
    }: {
        id: number;
        options: KitchenProductAddOnOption[];
    }) => {
        return (
            <div className="grid grid-cols-2 gap-2">
                {options.map((option) => (
                    <ProductAddOnOption
                        key={option.id}
                        id={option.id}
                        label={translate(option.name, i18n)}
                        name={`${"add-on-" + id}`}
                        type="radio"
                        checked={selectedOptionIds.has(option.id)}
                        onChange={() => {
                            setSelectedOptionIds((optionIds) => {
                                const updatedOptionIds = new Set(optionIds);

                                options.forEach((addOnOption) =>
                                    updatedOptionIds.delete(addOnOption.id)
                                );
                                updatedOptionIds.add(option.id);

                                return updatedOptionIds;
                            });
                        }}
                    />
                ))}
            </div>
        );
    };

    const ProductAddOn = ({ addOn }: { addOn: KitchenProductAddOn }) => {
        return (
            <div className="mb-8 text-center">
                <div
                    className={`
                     my-2 text-lg text-primary-500
                    ${
                        addOn.required &&
                        "after:ml-0.5 after:text-red-500 after:content-['*']"
                    }`}
                >
                    {translate(addOn.description, i18n)}
                </div>

                {addOn.multipleChoice ? (
                    <CheckboxGroup options={addOn.options} />
                ) : (
                    <RadioGroup id={addOn.id} options={addOn.options} />
                )}
            </div>
        );
    };

    const [isProductInformationOpen, setIsProductInformationOpen] =
        useState(false);

    return (
        <div className="z-10 flex h-full flex-col gap-3 bg-white p-3">
            <div className="flex items-center justify-between">
                <button
                    className="press-effect rounded bg-primary-200 p-1 text-primary-500"
                    onClick={() => onCancel()}
                >
                    {svg.leftArrow}
                </button>
                <div className="flex flex-col items-center text-center text-primary-500">
                    <img
                        src={product.imageUrl ?? placeholderImage}
                        className="h-20"
                    />
                    <span className="w-full overflow-hidden text-ellipsis text-xl font-semibold capitalize">
                        {translate(product.name, i18n).toLowerCase()}
                    </span>
                </div>
                <div className="h-8 w-8" />
            </div>

            <div className="flex-1 overflow-y-auto rounded-2xl bg-primary-100 px-6">
                {product.addOns.map((productAddOn) => (
                    <ProductAddOn addOn={productAddOn} key={productAddOn.id} />
                ))}
            </div>

            <button
                className="press-effect flex-none rounded bg-primary-500 p-2 text-center font-semibold leading-loose text-white disabled:opacity-30"
                disabled={
                    !product.addOns
                        .filter((productAddOn) => productAddOn.required)
                        .every((productAddOn) =>
                            productAddOn.options.some((option) =>
                                selectedOptionIds.has(option.id)
                            )
                        )
                }
                onClick={() =>
                    onConfirm(product.id, product.barcode, 1, [
                        ...selectedOptionIds
                    ])
                }
            >
                {t("done") +
                    (product.price ? ` (${Price.format(product.price)})` : "")}
            </button>

            {isProductInformationOpen && (
                <Dialog
                    className="bg-primary-500 text-center"
                    title={t("kitchenMenu.productInformation")}
                    theme="kitchen"
                    onBackgroundClick={() => setIsProductInformationOpen(false)}
                    primaryButton={{
                        title: t("done"),
                        action: () => setIsProductInformationOpen(false)
                    }}
                >
                    <div className="text-lg">
                        {t("kitchenMenu.nutritionalInformation")}
                    </div>
                    <div className="text-sm">
                        {translate(product.nutritionalInformation, i18n)}
                    </div>
                    <div className="text-lg">{t("kitchenMenu.allergens")}</div>
                    <div className="text-sm">
                        {translate(product.allergens, i18n)}
                    </div>
                </Dialog>
            )}
            <Button
                onClick={() => setIsProductInformationOpen(true)}
                label={t("kitchenMenu.productInformation")}
                className="w-full text-center opacity-70"
            />
        </div>
    );
};
