export const openConnection = async (device: USBDevice) => {
  await device.open();
  await device.selectConfiguration(1);
  await device.claimInterface(0);
};

export const closeConnection = async (device: USBDevice) => {
  await device.close();
};

export const printChunks = async (content: Uint8Array, device: USBDevice) => {
  const chunkSize = 64;
  for (let i = 0; i < content.length; i += chunkSize) {
    await device.transferOut(1, content.slice(i, i + chunkSize).buffer);
  }
};
