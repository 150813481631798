import { parse, consolePrint } from "./src/parser.js";
import { openConnection, closeConnection, printChunks } from "./src/printer.js";

declare global {
  interface Uint8Array {
    concat(other: Uint8Array): Uint8Array;
  }
}

export const printOrConsole = (device?: USBDevice) => {
  if (device)
    return async (xml: string) => {
      const printable = parse(xml);
      console.log("Start sending print commands");
      await openConnection(device);
      await printChunks(printable, device);
      await closeConnection(device);
      console.log("Finish sending print commands");
    };
  return async (xml: string) => consolePrint(xml);
};

export const requestDevice = async (vendorId: number, productId: number) => {
  return await navigator.usb.requestDevice({
    filters: [
      {
        vendorId: vendorId,
        productId: productId,
      },
    ],
  });
};

export const getDevice = async (vendorId: number, productId: number) => {
  const devices = await navigator.usb.getDevices();
  return devices.find(
    (device) => device.vendorId === vendorId && device.productId === productId
  );
};
