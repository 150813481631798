export const getSimulatePaymentResponse = () => {
    if (import.meta.env.MODE === "production") {
        return undefined;
    }

    const params = new URLSearchParams(location.search);
    const param = params.get("simulatePaymentResponse");

    switch (param) {
        case "success":
        case "failure":
        case "aborted":
            return param;
        default:
            return undefined;
    }
};
