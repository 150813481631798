import { printOrConsole, requestDevice, getDevice } from "@reto/printer";
import { useEffect, useState } from "react";

const isSupported = "usb" in navigator;

const VENDOR_ID = 0x04b8;
const PRODUCT_ID = 0x0202;

export const usePrinter = () => {
    const connectAndUpdateState = async () => {
        const device = await getDevice(VENDOR_ID, PRODUCT_ID);

        if (!device) return;

        setState((state) => ({
            ...state,
            connected: true,
            print: printOrConsole(device)
        }));
    };

    const [state, setState] = useState({
        connected: false,
        enabled: isSupported,
        print: printOrConsole(undefined),
        connect: () =>
            requestDevice(VENDOR_ID, PRODUCT_ID)
                .then(connectAndUpdateState)
                .then(() => console.log("Printer connected"))
                .catch(() =>
                    setState((state) => ({ ...state, enabled: false }))
                )
    });

    useEffect(() => {
        if (isSupported) {
            getDevice(VENDOR_ID, PRODUCT_ID).then((device) => {
                if (device) connectAndUpdateState();
            });

            navigator.usb.addEventListener("disconnect", onDisconnected);
            return () => {
                navigator.usb.removeEventListener("disconnect", onDisconnected);
            };
        }
    }, []);

    const onDisconnected = (event: USBConnectionEvent) => {
        if (
            event.device.vendorId === VENDOR_ID &&
            event.device.productId === PRODUCT_ID
        ) {
            setState((state) => ({ ...state, connected: false }));
        }
    };

    return state;
};
